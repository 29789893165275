@import 'abstracts/mixins';

@mixin icon-trash-hover() {
    background-image: url('/assets/img/trash-regular-monza-24.png');
    background-size: 18px 20px;
    background-position: 50%;

    @include retina {
        background-image: url('/assets/img/trash-regular-monza-24@2x.png');
    }
}

@mixin icon-selected-white() {
    background-image: url('/assets/img/selected-solid-white-24.png');
    background-size: 22px 22px;
    background-position: 50%;

    @include retina {
        background-image: url('/assets/img/selected-solid-white-24@2x.png');
    }
}

.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;

    &.icon-chevron-right {
        background-image: url('/assets/img/chevron-right.png');
        background-size: 12px 21px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/chevron-right@2x.png');
        }
    }

    &.icon-crosslink {
        background-image: url('/assets/img/crosslink.png');
        background-size: 22px 16px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/crosslink@2x.png');
        }
    }

    &.icon-recruiting {
        background-image: url('/assets/img/recruiting.png');
        background-size: 19px 19px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/recruiting@2x.png');
        }
    }

    &.icon-warning {
        background-image: url('/assets/img/warning.png');
        background-size: 22px 22px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/warning@2x.png');
        }
    }

    &.icon-home {
        background-image: url('/assets/img/home.png');
        background-size: 20px 20px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/home@2x.png');
        }
    }

    &.icon-references {
        background-image: url('/assets/img/ref.png');
        background-size: 36px 24px;
        width: 36px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/ref@2x.png');
        }
    }

    &.icon-search {
        background-image: url('/assets/img/search.png');
        background-size: 27px 24px;
        width: 27px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/search@2x.png');
        }
    }

    &.icon-magnifiying-glass {
        background-image: url('/assets/img/magnifiying-glass.png');
        background-size: 15px 16px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/magnifiying-glass@2x.png');
        }
    }

    &.icon-logout {
        background-image: url('/assets/img/logout.png');
        background-size: 20px 21px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/logout@2x.png');
        }
    }

    &.icon-package {
        background-image: url('/assets/img/package.png');
        background-size: 21px 22px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/package@2x.png');
        }
    }

    &.icon-chevron-down {
        background-image: url('/assets/img/chevron-down.png');
        background-size: 21px 12px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/chevron-down@2x.png');
        }
    }

    &.icon-chevron-right-white {
        background-image: url('/assets/img/chevron-right-white.png');
        background-size: 8px 12px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/chevron-right-white@2x.png');
        }
    }

    &.icon-content-available {
        background-image: url('/assets/img/content-available.png');
        background-size: 24px 24px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/content-available@2x.png');
        }
    }

    &.icon-content-unavailable {
        background-image: url('/assets/img/content-unavailable.png');
        background-size: 24px 24px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/content-unavailable@2x.png');
        }
    }

    &.icon-content-is-visible {
        background-image: url('/assets/img/content-is-visible.png');
        background-size: 24px 14px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/content-is-visible@2x.png');
        }
    }

    &.icon-content-is-hidden {
        background-image: url('/assets/img/content-is-hidden.png');
        background-size: 24px 17px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/content-is-hidden@2x.png');
        }
    }

    &.icon-house-outline-white {
        background-image: url('/assets/img/house-outline-white.png');
        background-size: 20px 20px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/house-outline-white@2x.png');
        }
    }

    &.icon-corona {
        background-image: url('/assets/img/corona.png');
        background-size: 21px 24px;
        background-position: 50%;

        @include retina {
            background-image: url('/assets/img/corona@2x.png');
        }
    }

    &.icon-edition {
        background-image: url('/assets/img/roche-logo.png');
        background-size: 30px 15px;
        background-position: 50%;
        //display: none;

        @include retina {
            background-image: url('/assets/img/roche-logo@2x.png');
        }
    }

    &.icon-stats {
        background-image: url('/assets/img/chevron-right.png');
        background-size: 15px 30px;
        background-position: 50%;
        transform: rotate(90deg);
        @include retina {
            background-image: url('/assets/img/chevron-right@2x.png');
        }
    }


}