@import 'fonts/imago';
@import 'fonts/minion';
@import 'icons/roche';
@import 'abstracts/variables';
@import 'abstracts/mixins';

body {
    font-family: $font-base;
}

.moa-substance-description, .moa-target-description{
    a {
        color: $color-white;
        text-decoration: underline;
    
        &:hover{
            color: $color-link-water;
        }
    }
}


b, strong {
    font-weight: 500 !important;
}

h1.page-headline {
    font-family: $font-alternate;
    font-size: 28px;
    color: $color-science-blue;

    .icon {
        width: 29px;
        height: 29px;
        vertical-align: top;
    }
}

span.required {
    color: $color-science-blue;
}

p.required-legend span.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-right: 12px;
    background-repeat: no-repeat;
    vertical-align: text-bottom;
    background-image: url('/assets/img/mandatory-regular-blue-24.png');
    background-size: 24px 19px;
    background-position: 50%;

    @include retina {
        background-image: url('/assets/img/mandatory-regular-blue-24@2x.png');
    }
}

/* NG_ZORRO OVERRIDES */

.ant-layout-header,
.ant-layout-footer {
    padding: 0px;
    height: auto;
}

.ant-layout-header{
  line-height: 64px;
}

.ant-form-item-label {
    line-height: initial;
}

.ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 16px;
}

li.ant-dropdown-menu-item {
    display: flex;
    align-items: center;

    i.icon {
        margin-right: 8px;
    }
}

.ant-form-item-label {
    color: $color-mine-shaft;

    label {
        color: $color-mine-shaft
    }
}

nz-alert .ant-alert {
    padding: 16px !important;
    text-align: center;

    span {
        color: $color-dove-gray !important;
    }
}

.ant-calendar-header {
    .ant-calendar-prev-year-btn,
    .ant-calendar-next-year-btn {
        display: none;
    }

    .ant-calendar-prev-month-btn {
        left: 7px;
    }

    .ant-calendar-next-month-btn {
        right: 7px;
    }
}


/* Custom styles based on NG_ZORRO */

.ant-btn {
    display: flex;
    align-items: center;

    &.rounded:not(.ant-btn-circle) {
        border-radius: 24px;
    }

    &.ant-btn-primary {
        &[disabled] {
            color: $color-white;
            background: $color-science-blue;
            border-color: $color-science-blue;
        }
    }

    &.ant-btn-danger {
        background: $color-cardinal;
        border-color: $color-cardinal;
        color: $color-white;

        &:hover {
            background: transparentize($color: $color-cardinal, $amount: 0.2);
        }

    }

    &.white {
        border: none;
        color: $color-science-blue;

        &[disabled] {
            color: $color-science-blue;
        }

        &.cardinal {
            color: $color-cardinal;
        }
    }

    &.green-haze {
        background: $color-green-haze;
        border-color: $color-green-haze;

        &[disabled] {
            background: $color-green-haze;
            border-color: $color-green-haze;
            color: $color-white;
        }
    }

    &.ant-btn-circle {
        i.icon {
            margin: 0 auto;
        }
    }

    &[disabled] {
        opacity: 0.6;
    }

}

.ant-popover-buttons .ant-btn,
.ant-modal-footer .ant-btn,
.ant-modal-confirm-btns .ant-btn {
    display: inline-block;
}

input.ant-input.required,
nz-date-picker.required input,
nz-time-picker.required input,
nz-select.required .ant-select-selection,
nz-input-number.required,
textarea.required {
    border: 2px solid $color-science-blue;

    &:hover {
        border-right-width: 2px !important;
    }

    &:focus {
        border-right-width: 2px !important;
    }
}

.has-error .ant-input, .has-error .ant-input:hover {
    border-color: #FF0000 !important;
}

nz-date-picker.required,
nz-time-picker.required {
    input {
        &:focus {
            border-color: $color-science-blue;
        }
    }
}

nz-select.required.ant-select-open .ant-select-selection {
    border-right-width: 2px !important;
}

nz-checkbox-wrapper.required .ant-checkbox .ant-checkbox-inner,
nz-checkbox-group.required .ant-checkbox .ant-checkbox-inner {
    border: 2px solid $color-science-blue;
    border-radius: 3px;

    &:after {
        top: 46%;
        left: 16%;
    }
}

nz-radio-group.required .ant-radio .ant-radio-inner {
    border: 2px solid $color-science-blue;

    &:after {
        top: 2px;
        left: 2px;
    }
}

/* Roche Pipeline FR specific */

nz-footer {
    background: transparent !important;
    padding: 0px !important;

    position: absolute;
    width: 100%;
    bottom: 0;
}

.instructions-text {
  font-family: Helvetica;
  font-size: 16px;
  color: #0066B3;
  letter-spacing: 0;
}

.roche-list {
  margin: 0;
  padding: 0;
}

.roche-round-corners {border-radius: $roche-border-radius;}
.roche-image-round { border-radius: 50%;}
.roche-image-fullwidth { width: 100%; }
.roche-text-center { text-align: center; }
.roche-text-left { text-align: left; }
.roche-text-right { text-align: right; }
.roche-mt-20 {margin-top: 20px;}
.roche-mb-20 {margin-bottom: 20px;}
.roche-mt-50 {
  margin-top: 50px;
  @include breakpoint(max-width, 1024) {
    margin-top: 25px;
  }
}
.roche-mb-50 {
  margin-bottom: 50px;
  @include breakpoint(max-width, 1024) {
    margin-bottom: 25px;
  }
}
.roche-pt-20 {padding-top: 20px;}
.roche-pb-20 {padding-bottom: 20px;}
.roche-pt-50 {padding-top: 50px;}
.roche-pb-50 {padding-bottom: 50px;}
.roche-mr-10 {margin-right: 10px;}
.roche-ml-10 {margin-left: 10px;}
.roche-font-uppercase {text-transform: uppercase;}
.roche-font-base {font-family: $font-base;}
.roche-font-bold {font-weight: 500;}
.roche-display-block {display: block;}
.roche-display-inline-block {display: inline-block;}
.roche-color-white {color:$color-white;}
.roche-background-white {background-color: $color-white;}
.roche-color-blue {color: $color-science-blue;}
.roche-position-relative{position: relative;}
.roche-pill{
  background-color: $color-white;
  color: $color-science-blue;
  font-family: $font-base;
  font-weight: 500;
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
}

.roche-title-page {
  display: block;
  font-family: "ImagoPro", Arial, sans-serif;
  color: #0066CC;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.10rem;
}

.roche-title-section {
  color: $color-white;
  text-transform: uppercase;
  font-size: $roche-title-section-font-size;
  /* margin: 0; */
}

.list-item, .roche-transition {
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.list-item:hover{
  transform: scale(1.008);
  cursor: pointer;
}

.ant-switch{
  background-color: $color-alto;
  min-width: 40px;
  height: 25px;

  .ant-switch-handle::before{
    width: 21px;
    height: 21px;
  }
}



.ant-switch-checked{
  background-color: $color-green;

    .ant-switch-handle {
        left: calc(100% - 18px - 5px);
    }
}

.ant-tabs-nav{
  display: flex;
  width: 100%;
  justify-content: center;
  background: $color-white;
}

.router-wrapper {
    position: relative;
    /* background-image: linear-gradient(0deg, $tab-background-bottom-gradient 0%, $tab-background-top-gradient 100%); */
    background-color: $tab-background-bottom-gradient;
    color: $color-white;
    min-height: calc(100vh - 76px);
    padding-bottom: 64px;
}

.ps{

  .ps__rail-y{
    background-color: transparent !important;
  }

}

.custom-container{
  max-width: 1500px;
  margin: 0 auto;
}


.background-white{
  background-color: $color-white;
}
