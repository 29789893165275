@font-face {
    font-family: 'ImagoPro';
    src: url('/assets/fonts/Imago-Medium.eot');
    src: url('/assets/fonts/Imago-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Imago-Medium.woff2') format('woff2'),
        url('/assets/fonts/Imago-Medium.woff') format('woff'),
        url('/assets/fonts/Imago-Medium.ttf') format('truetype'),
        url('/assets/fonts/Imago-Medium.svg#Imago-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ImagoPro';
    src: url('/assets/fonts/Imago-MediumItalic.eot');
    src: url('/assets/fonts/Imago-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Imago-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Imago-MediumItalic.woff') format('woff'),
        url('/assets/fonts/Imago-MediumItalic.ttf') format('truetype'),
        url('/assets/fonts/Imago-MediumItalic.svg#Imago-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'ImagoPro';
    src: url('/assets/fonts/Imago-Book.eot');
    src: url('/assets/fonts/Imago-Book.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Imago-Book.woff2') format('woff2'),
        url('/assets/fonts/Imago-Book.woff') format('woff'),
        url('/assets/fonts/Imago-Book.ttf') format('truetype'),
        url('/assets/fonts/Imago-Book.svg#Imago-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ImagoPro';
    src: url('/assets/fonts/Imago-BookItalic.eot');
    src: url('/assets/fonts/Imago-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Imago-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/Imago-BookItalic.woff') format('woff'),
        url('/assets/fonts/Imago-BookItalic.ttf') format('truetype'),
        url('/assets/fonts/Imago-BookItalic.svg#Imago-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

