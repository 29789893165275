
$color-black: #000000;
$color-white: #FFFFFF;
$color-science-blue: #0066CC;
$color-mine-shaft: #333333;
$color-concrete: #F2F2F2;
$color-cardinal: #CF1A3B;
$color-monza: #CC0033;
$color-silver: #CCCCCC;
$color-seagull: #7FB2E5;
$color-link-water: #E5EFFA;
$color-dusty-gray: #999999;
$color-green-haze: #009966;
$color-dove-gray: #666666;
$color-alto: #D8D8D8;
$color-azaela: #F5CCD6;
$color-green: #34c759;
$color-worn-blue: #5689C5;
$color-roche-blue: #0066B3;
$color-event-selected: #F0F9FF;
$color-error: #FF3418;

$gradient-blue-horizontal: linear-gradient(142deg, $color-science-blue 0%, $color-seagull 100%);
$gradient-blue-vertical: linear-gradient(180deg, $color-seagull 0%, $color-science-blue 100%);

$font-base: 'ImagoPro', Arial, sans-serif;
$font-alternate: 'Minion', serif;

$tab-background-color : #8BA4B7;
$tab-active-background-color: #051E31;
$tab-background-top-gradient: $tab-active-background-color;
$tab-background-bottom-gradient: #082E49;
$tab-font-size : 0.85rem;

$roche-title-section-font-size : 1.05rem;
$roche-border-radius: 4px;
