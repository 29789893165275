@font-face {
    font-family: 'Minion';
    src: url('/assets/fonts/Minion-Italic.eot');
    src: url('/assets/fonts/Minion-Italic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Minion-Italic.woff2') format('woff2'),
        url('/assets/fonts/Minion-Italic.woff') format('woff'),
        url('/assets/fonts/Minion-Italic.ttf') format('truetype'),
        url('/assets/fonts/Minion-Italic.svg#Minion-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Minion';
    src: url('/assets/fonts/Minion-Bold.eot');
    src: url('/assets/fonts/Minion-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Minion-Bold.woff2') format('woff2'),
        url('/assets/fonts/Minion-Bold.woff') format('woff'),
        url('/assets/fonts/Minion-Bold.ttf') format('truetype'),
        url('/assets/fonts/Minion-Bold.svg#Minion-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Minion';
    src: url('/assets/fonts/Minion-BoldItalic.eot');
    src: url('/assets/fonts/Minion-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Minion-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Minion-BoldItalic.woff') format('woff'),
        url('/assets/fonts/Minion-BoldItalic.ttf') format('truetype'),
        url('/assets/fonts/Minion-BoldItalic.svg#Minion-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Minion';
    src: url('/assets/fonts/Minion-Regular.eot');
    src: url('/assets/fonts/Minion-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Minion-Regular.woff2') format('woff2'),
        url('/assets/fonts/Minion-Regular.woff') format('woff'),
        url('/assets/fonts/Minion-Regular.ttf') format('truetype'),
        url('/assets/fonts/Minion-Regular.svg#Minion-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

